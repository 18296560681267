import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, NavLink, Link } from "react-router-dom";
import "./App.css";
import { ReactComponent as Logo } from "./assets/logo.svg";
import productRotationGif from "./assets/product-rotation.gif";
import productImage1 from "./assets/product1.png";
import productImage2 from "./assets/product2.png";
import productImage3 from "./assets/product3.png";
import amazonLogo from "./assets/amazon-logo.png";
import sectionImage1 from "./assets/section1.png";
import sectionImage2 from "./assets/section2.png";
import sectionImage3 from "./assets/section3.png";
import sellingImage1 from "./assets/selling1.png";
import sellingImage2 from "./assets/selling2.png";
import sellingImage3 from "./assets/selling3.png";
import sellingImage4 from "./assets/selling4.png";
import customTextSaucerImage from "./assets/saucer.png";
import plantationCertificate from "./assets/plantation-certificate.png"; // Add the image for the plantation certificate

const SellingPoints = () => (
  <div className="content selling-points">
    {[
      {
        image: sellingImage1,
        title: "Revolutionary 3D Printing",
        description:
          "We use cutting-edge 3D printing technology to manufacture our planters. Our planters are made with a material stronger than regular plastic, ensuring durability and longevity.",
      },
      {
        image: sellingImage2,
        title: "Stunning AI Replicas",
        description:
          "Our AI technology allows us to create stunning and amazingly accurate replicas of people's heads using just a single image. We can also add depth to regular 2D photos, bringing your memories to life.",
      },
      {
        image: sellingImage3,
        title: "Biodegradable and Eco-Friendly",
        description:
          "Our planters are made from biodegradable materials, making them eco-friendly and sustainable. Additionally, having plants in your indoor spaces can have a positive impact on the environment and your well-being.",
      },
      {
        image: sellingImage4,
        title: "Emotional Value and Personalized Gifts",
        description:
          "Our planters hold emotional value as they can preserve memories of special moments or special people in your life. They also make amazing personalized gifts, allowing you to create unique and meaningful presents.",
      },
    ].map(({ image, title, description }, index) => (
      <div key={index} className="selling-section">
        <div className="selling-image">
          <img src={image} alt={title} className="rounded-image" />
        </div>
        <div className="selling-description">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </div>
    ))}
    <ExtraCard /> {/* Add the extra card */}
  </div>
);

const ExtraCard = () => {
  const [isEnlarged, setIsEnlarged] = useState(false);

  const handleEnlarge = () => {
    setIsEnlarged(true);
  };

  const handleClose = () => {
    setIsEnlarged(false);
  };

  return (
    <div className="extra-card">
      
      <div className="extra-card-text">
      <h2>Our Pledge</h2>
        For every planter sold, we plant a fruit-bearing tree through Project Sabuja Odisha. This supports Odisha farmers affected by Cyclone Fani, enhancing their food security and providing alternative income sources.
      </div>
      <img src={plantationCertificate} alt="Plantation Certificate" onClick={handleEnlarge} />
      {isEnlarged && (
        <div className="enlarged-view" onClick={handleClose}>
          <img src={plantationCertificate} alt="Plantation Certificate" />
        </div>
      )}
    </div>
  );
};

const Home = () => (
  <div>
    <div className="content">
      <div className="gif-container">
        <img src={productRotationGif} alt="Product Rotation" className="gif" />
      </div>
      <h1 className="title">SIGMA PLANTER</h1>
    </div>
    <SellingPoints />
  </div>
);

const ProductCard = ({ image, name, description, price, amazonLink, isBestseller, isAddon }) => (
  <div className={`product-card${isAddon ? " addon" : ""}`}>
    <div className={`product-image-container${isBestseller ? " bestseller" : ""}`} style={{ position: "relative" }}>
      {isBestseller && <div className="bestseller-tag">Bestseller</div>}
      <img src={image} alt={name} className="product-image" />
    </div>
    <div className="product-details">
      <h3 className="product-name">
        {name}
        {isBestseller && <span className="bestseller-tag-mobile">Bestseller</span>}
      </h3>
      <p className="product-description">{description}</p>
      {amazonLink && (
        <a href={amazonLink} target="_blank" rel="noopener noreferrer" className="buy-now-link">
          <img src={amazonLogo} alt="Buy on Amazon" style={{ width: "20px", marginRight: "5px" }} />
          Buy Now
        </a>
      )}
    </div>
  </div>
);

const Products = () => (
  <div className="content">
    <h2 className="products-title">Products</h2>
    <div className="product-carousel">
      {[
        {
          image: productImage1,
          name: "Sculpture Planter",
          description: "AI generated sculpture created using a single image",
          price: "1999",
          amazonLink: "https://www.amazon.in/dp/B0CZTVTQZ1",
        },
        {
          image: productImage2,
          name: "Portrait Planter",
          description: "Image of your choice is embossed onto the planter",
          price: "499",
          amazonLink: "https://www.amazon.in/dp/B0CY9SX9L6",
          isBestseller: true,
        },
        {
          image: productImage3,
          name: "Message Planter",
          description: "Message of your choice is embossed onto the planter",
          price: "249",
          amazonLink: "https://www.amazon.in/dp/B0CYXPPSVV?th=1",
        },
        {
          image: customTextSaucerImage,
          description: "Custom text planter saucer included with all our planters",
          price: "99",
          isAddon: true,
        },
      ].map((product, index) => (
        <ProductCard key={index} {...product} />
      ))}
    </div>
  </div>
);

const Mission = () => (
  <div className="content mission-page">
    {[
      {
        image: sectionImage1,
        title: "Our Mission",
        description:
          "Today, as technology redefines the world we live in, our environment is compromised in ways beyond our fathom. We are in constant turmoil between our fear of destabilizing the ecosystem and our aspiration for progress. At Sigma Planter, we believe in bridging the gap between nature, technology, and human emotion.",
        sectionClass: "blue-section",
      },
      {
        image: sectionImage2,
        title: "Our Approach",
        description:
          "We manufacture bio-degradable planters using 3D printing technology, harmoniously merging the best of both worlds. Our planters can be personalized by the customer, allowing individuals to express their creativity while embracing nature. The combination of personalized planters and the beauty of the natural world evokes emotions of peace and enhances our relationship with the environment.",
        sectionClass: "green-section",
      },
      {
        image: sectionImage3,
        title: "Our Vision",
        description:
          "At Sigma Planter, we envision a future where technology and nature coexist harmoniously. We strive to create innovative, sustainable, and aesthetically pleasing products that not only cater to individual needs but also nurture the planet. Through our unique approach, we aim to inspire others to appreciate the beauty of the natural world while embracing technological advancements for a greener tomorrow.",
        sectionClass: "blue-section",
      },
    ].map(({ image, title, description, sectionClass }, index) => (
      <div key={index} className={`mission-section ${sectionClass}`}>
        <div className="section-image">
          <img src={image} alt={title} className="rounded-image" />
        </div>
        <div className="mission-description">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </div>
    ))}
    <ExtraCard /> {/* Add the extra card */}
  </div>
);

const Contact = () => (
  <div className="content contact-page">
    <h2 className="contact-title">Contact Us</h2>
    <div className="contact-info">
      <p>Email: my@sigmaplanter.com</p>
      {/* <p>Phone/WhatsApp: +91 76740 97596</p> */}
    </div>
  </div>
);

const App = () => (
  <Router>
    <header className="header">
      <nav className="navbar">
        <ul className="navbar-nav">
          <li className="nav-item logo-item">
            <Link to="/" className="logo-link">
              <Logo className="logo" />
            </Link>
          </li>
          {[
            { path: "/products", label: "Products" },
            { path: "/mission", label: "Our Mission" },
            { path: "/contact", label: "Contact Us" },
            { path: "https://amazon.in/sigmaplanter", label: "Amazon Store", external: true, img: amazonLogo },
          ].map(({ path, label, external, img }, index) => (
            <li key={index} className="nav-item">
              <NavLink to={path} className="nav-link" activeClassName="active" target={external ? "_blank" : "_self"}>
                {label}
                {img && <img src={img} alt="Buy on Amazon" style={{ width: "13px", marginLeft: "10px", alignSelf: "auto" }} />}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </header>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/products" element={<Products />} />
      <Route path="/mission" element={<Mission />} />
      <Route path="/contact" element={<Contact />} />
    </Routes>
    <footer className="footer">
      <p>© {new Date().getFullYear()} Sigma Planter. All rights reserved.</p>
    </footer>
  </Router>
);

export default App;
